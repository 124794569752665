import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setByFlag } from '@store/actionSlices/houseAndLand'
import type {
  LotFilterInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { MarkerType } from '@components/showcase-canvas/constants'
import { Polygon } from '@components/showcase-canvas/types'

import { MarkerColourInterface } from '@api/config'
import { MapContent } from '@api/interactive-plan'
import {
  HouseAndLandActiveKeyType,
  LotInterface,
  LotStatusType,
} from '@api/types/house-and-land-type'

import analyzePixels from '@utilities/analyze-pixels'
import AssetHandler from '@utilities/asset-handler'
import styleUtil from '@utilities/style-util'

import { CloseSvg, FullScreenSvg } from '@svg/react'

export interface StageCanvasProps {
  stagePlan: MapContent
  originalLots: Array<LotInterface>
  filteredLots: Array<LotInterface>
  activePrecinctId: string
  activeStageId: string
  isFullWidth: boolean
  session: SessionMap | undefined
  lotFilter: LotFilterInterface
  markerColour: MarkerColourInterface
  ratio?: string
  fullScreenToggle: boolean
  setFullScreenToggle: (arg: boolean) => void
  disableSoldLot: boolean
}

const StageCanvas = ({
  stagePlan,
  originalLots,
  filteredLots,
  lotFilter,
  session,
  activePrecinctId,
  activeStageId,
  isFullWidth: isFullWidthProp,
  markerColour,
  ratio = 'max',
  fullScreenToggle,
  setFullScreenToggle,
  disableSoldLot,
}: StageCanvasProps) => {
  const themeData = styleUtil()
  const dispatch = useDispatch()

  const prevReference = React.useRef<{
    activeStageId: string
    activePrecinctId: string
    activeImage: string
  }>({
    activeStageId,
    activePrecinctId,
    activeImage: '',
  })

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()

  const [isStagePlanImageLoaded, setImageLoaded] = React.useState(false)
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [isConnected, setIsConnected] = React.useState(false)
  const [isFullWidth, setIsFullWidth] = React.useState(isFullWidthProp)
  const [backgroundColor, setBackgroundColor] =
    React.useState('rgb(255, 255, 255)')

  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })

  const [stagePlanMap, setStagePlanMap] = React.useState<MapContent>()

  const arrowMarkerFilterLogic = React.useCallback(
    (lot: LotInterface) => {
      const { apply } = lotFilter

      if (!apply) {
        return false
      }

      const foundLot =
        filteredLots.find((item) => item.id === lot.id) !== undefined

      return !foundLot
    },
    [lotFilter, filteredLots]
  )

  const badgeAndNumberedFilterLogic = React.useCallback(
    (lot: LotInterface) => {
      const { apply } = lotFilter

      if (!apply) {
        return false
      }

      const foundLot =
        filteredLots.find((item) => item.id === lot.id) !== undefined

      return foundLot
    },
    [lotFilter, filteredLots]
  )

  const checkLotStatus = (lot: LotInterface, type: string) => {
    if (disableSoldLot && lot.status === LotStatusType.Sold) {
      return true
    }
    if (type === MarkerType.arrowSmall || type === MarkerType.arrow) {
      return arrowMarkerFilterLogic(lot)
    }
    return badgeAndNumberedFilterLogic(lot)
  }

  const isSoldLotDisabled = (lot: LotInterface) => {
    if (disableSoldLot && lot.status === LotStatusType.Sold) {
      return true
    }
    return !lot
  }

  const setLotColor = (status: string, poly: Polygon) => {
    if (poly.type === MarkerType.arrowSmall) {
      return poly?.color
    }
    if (status.toLowerCase() === LotStatusType.Available) {
      return poly?.color || '#0abec4'
    }
    return '#8F8E8E'
  }

  const getMarkerColour = (status: string) =>
    status === LotStatusType.Sold ? markerColour.sold : markerColour.available

  const handleLotData = (poly: Polygon) => {
    const lot = originalLots.find(
      (res: LotInterface) =>
        res.name.replace(/\s/g, '') === poly?.groupId?.replace(/\s/g, '')
    )
    return {
      disabled: lot && isSoldLotDisabled(lot),
      postFix: lot?.status,
      color: setLotColor(lot?.status || '', poly),
      markerColor: getMarkerColour(lot?.status || ''),
      activeByDefault: lot && checkLotStatus(lot, poly?.type || ''),
    }
  }

  const getLotIdByGroupId = React.useCallback(
    (groupId: string): string => {
      if (originalLots.length === 0) {
        return ''
      }

      const foundLot = originalLots.find(
        (item: LotInterface) =>
          item.name.replace(/\s/g, '') === groupId.replace(/\s/g, '')
      )

      if (!foundLot) {
        return ''
      }

      return foundLot.id
    },
    [originalLots]
  )

  const getGroupIdByLotId = React.useCallback(
    (lotId: string): string => {
      if (originalLots.length === 0) {
        return ''
      }

      const foundLot = originalLots.find((item) => item.name === lotId)
      if (!foundLot) {
        return ''
      }

      return foundLot.name
    },
    [originalLots]
  )

  const mapStagePlanActions = React.useCallback(() => {
    if (!stagePlan) {
      return
    }
    setStagePlanMap({
      ...stagePlan,
      image: stagePlan.image,
      polygons: stagePlan.polygons
        .map((poly: Polygon) => ({
          ...poly,
          ...handleLotData(poly),
          noArrow: true,
          onClick: () => {
            if (poly.groupId) {
              dispatch(
                setByFlag({
                  flag: HouseAndLandActiveKeyType.ActiveLotId,
                  value: getLotIdByGroupId(poly.groupId),
                })
              )
            }
          },
        }))
        .filter((res: Polygon) =>
          originalLots.find(
            (lotItem: LotInterface) =>
              lotItem.name.replace(/\s/g, '') ===
              res?.groupId?.replace(/\s/g, '')
          )
        ),
    })
  }, [stagePlan, lotFilter, originalLots])

  const handleFullScreenToggle = () => {
    setFullScreenToggle(!fullScreenToggle)
    requestAnimationFrame(() => {
      canvasRef?.current?.setCanvas()
    })
  }

  const handleBackgroundColor = React.useCallback(() => {
    if (stagePlan) {
      const url = AssetHandler({
        url: stagePlan.image,
        type: 'new',
        noSpliceUrl: true,
      })
      const dynamicImage = document.createElement('img')
      dynamicImage.crossOrigin = 'Anonymous'
      dynamicImage.src = url
      dynamicImage.onload = () => {
        const color = analyzePixels(dynamicImage)
        const rgb = `rgb(${color[0]}, ${color[1]}, ${color[2]})`
        setBackgroundColor(rgb)
      }
    }
  }, [stagePlan])

  React.useEffect(() => {
    if (stagePlan && canvasContainerRef && originalLots.length > 0) {
      mapStagePlanActions()
      setRenderCanvas(true)
    }
  }, [stagePlan, lotFilter, originalLots])

  const handleCanvasItems = () => {
    canvasRef?.current?.setPolyActive()
  }

  React.useEffect(() => {
    if (!stagePlanMap) {
      return
    }
    if (
      activeStageId !== prevReference.current.activeStageId ||
      activePrecinctId !== prevReference.current.activePrecinctId ||
      stagePlanMap.image !== prevReference.current.activeImage
    ) {
      setTimeout(() => canvasRef?.current?.setCanvas(), 200)
    }
    prevReference.current = {
      ...prevReference.current,
      activeStageId,
      activePrecinctId,
      activeImage: stagePlanMap.image,
    }
    handleCanvasItems()
  }, [stagePlanMap])

  React.useEffect(() => {
    if (isFullWidth !== isFullWidthProp) {
      requestAnimationFrame(() => {
        canvasRef?.current?.setCanvas()
      })
      setIsFullWidth(isFullWidthProp)
    }
  }, [isFullWidthProp])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: { activeLotId: activeLotIdFirebase },
      } = session
      setIsConnected(connected)

      if (activeLotIdFirebase) {
        const groupId = getGroupIdByLotId(activeLotIdFirebase)
        if (groupId) {
          canvasRef?.current?.artificialTrigger(groupId)
        }
      }
    }
  }, [session?.connected, session?.houseAndLand.activeLotId])

  React.useLayoutEffect(() => {
    handleBackgroundColor()
  }, [stagePlan])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  return (
    <div
      className="relative h-full w-full bg-white"
      style={{ backgroundColor }}
    >
      {!isConnected && (
        <div className="absolute left-0 top-0 z-10 p-4">
          <button
            type="button"
            onClick={handleFullScreenToggle}
            className="rounded bg-[#747474] p-2"
          >
            {fullScreenToggle ? (
              <CloseSvg className="h-6 w-6 text-white" />
            ) : (
              <FullScreenSvg className="h-6 w-6 text-white" />
            )}
          </button>
        </div>
      )}
      <div className="h-full w-full">
        <div
          className={`relative flex aspect-video h-full w-screen items-center justify-center ${
            isStagePlanImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          style={
            !fullScreenToggle && !isConnected
              ? { maxWidth: 'calc(100vw - 297px)' }
              : undefined
          }
          ref={canvasContainerRef}
        >
          {stagePlanMap && renderCanvas && (
            <CanvasInteractive
              ref={canvasRef}
              id="stage-canvas"
              canvasData={stagePlanMap}
              parentRef={canvasContainerRef}
              hasLabel={!isConnected}
              labelPrefix="Lot"
              showLabelPrefix
              ratio={ratio}
              theme={{
                brandColour: theme?.mainColour || themeData.mainColour || '',
                font: theme?.font || themeData.font || '',
              }}
              adjustCanvasSizeWithContainer
              setImageLoaded={setImageLoaded}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    lotFilter,
    houseAndLand: { activePrecinctId, activeStageId },
    projectConfig: { markerColour, showPrice, disableSoldUnit },
  }: RootStateFirebase) => ({
    lotFilter,
    activePrecinctId,
    activeStageId,
    markerColour,
    showPrice,
    disableSoldLot: disableSoldUnit,
  })
)(StageCanvas)
