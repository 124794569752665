import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { HouseAndLandInterface } from '@store/actionSlices/houseAndLand'
import { setPanoramicData } from '@store/actionSlices/panoramic'
import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import PannellumView from '@components/showcase-pannellum'
import {
  PannellumDataInterface,
  PannellumViewRefInterface,
  PanoramaImageType,
  PanoramaType,
} from '@components/showcase-pannellum/types'
import Skeleton from '@components/skeleton'

import { selectFromResult, useGetPanoramicQuery } from '@api/panoramic'

import getSession from '@utilities/firebase-util'
import {
  PANORAMIC_ACTIVE_LOT_KEY,
  PANORAMIC_ACTIVE_PRECINCT_KEY,
  PANORAMIC_ACTIVE_STAGE_KEY,
  getItem,
  removeItem,
  setItem,
} from '@utilities/localstorage'

import { ArrowSvg } from '@svg/react'

interface HouseAndLandProps {
  projectName: string
  panoramic: Array<PannellumDataInterface>
  session: SessionMap | undefined
  houseAndLand: HouseAndLandInterface
}

interface HouseAndLandFlagInterface {
  set: boolean
  activePrecinct: string
  activeStage: string
  activeLot: string
}

const defaultHouseAndLandFlag = {
  set: false,
  activePrecinct: '',
  activeStage: '',
  activeLot: '',
}

const HouseAndLand = ({
  projectName,
  panoramic,
  session,
  houseAndLand,
}: HouseAndLandProps) => {
  const dispatch = useDispatch()

  const pannellumViewRef = React.useRef<PannellumViewRefInterface>()

  const [houseAndLandFlag, setHouseAndLandFlag] =
    React.useState<HouseAndLandFlagInterface>(defaultHouseAndLandFlag)

  const [activeScene, setActiveScene] = React.useState('')
  const [activePanoramaType, setActivePanoramaType] =
    React.useState<PanoramaImageType>(PanoramaImageType.Sphere)
  const [isLoadingActiveScene, setActiveSceneLoadingState] =
    React.useState(true)

  const [isConnected, setIsConnected] = React.useState(false)
  const [scene, setScene] = React.useState('')
  const [zoom, setZoom] = React.useState<number | undefined>(undefined)
  const [pitch, setPitch] = React.useState<number | undefined>(undefined)
  const [yaw, setYaw] = React.useState<number | undefined>(undefined)
  const [rotationSpeed, setRotationSpeed] = React.useState<number | undefined>(
    undefined
  )

  const panoramicPayload = useGetPanoramicQuery(
    { projectName },
    { selectFromResult }
  )

  const findPanoramic = (type: PanoramaType, targetName: string) =>
    panoramic.find(
      (data) =>
        data.type === type && data.panoramaGroup?.toString() === targetName
    )

  const getActivePanoramic = (hlData: HouseAndLandFlagInterface) => {
    const { activePrecinct, activeStage, activeLot } = hlData

    if (activeLot !== '') {
      return findPanoramic(PanoramaType.Lot, activeLot.toString())
    }

    if (activeStage !== '')
      return findPanoramic(PanoramaType.Stage, activeStage.toString())

    if (activePrecinct !== '')
      return findPanoramic(PanoramaType.Precinct, activePrecinct.toString())

    return null
  }

  const storeHouseAndLandDataToLocalstorage = (
    myData: HouseAndLandInterface
  ): void => {
    const { activePrecinctId, activeStageId, activeLotId, activeLotName } =
      myData

    let localstoragePrecinct = getItem(PANORAMIC_ACTIVE_PRECINCT_KEY, '')
    let localstorageStage = getItem(PANORAMIC_ACTIVE_STAGE_KEY, '')
    let localstorageLot = getItem(PANORAMIC_ACTIVE_LOT_KEY, '')

    if (activePrecinctId && activePrecinctId !== localstoragePrecinct)
      localstoragePrecinct = activePrecinctId

    if (activeStageId && activeStageId !== localstorageStage)
      localstorageStage = activeStageId

    if (activeLotId && activeLotName && activeLotName !== localstorageLot)
      localstorageLot = activeLotName

    setItem(PANORAMIC_ACTIVE_PRECINCT_KEY, localstoragePrecinct)
    setItem(PANORAMIC_ACTIVE_STAGE_KEY, localstorageStage)
    setItem(PANORAMIC_ACTIVE_LOT_KEY, localstorageLot)

    setHouseAndLandFlag({
      set: true,
      activePrecinct: localstoragePrecinct,
      activeStage: localstorageStage,
      activeLot: localstorageLot,
    })
  }

  React.useEffect(() => {
    const { panoramic: panoramicData, isLoaded } = panoramicPayload
    if (panoramic.length === 0 && isLoaded && panoramicData.length > 0) {
      dispatch(setPanoramicData(panoramicData))
    }
  }, [panoramicPayload, panoramic])

  React.useEffect(() => {
    storeHouseAndLandDataToLocalstorage(houseAndLand)
  }, [houseAndLand])

  React.useEffect(() => {
    if (houseAndLandFlag.set && panoramic.length > 0) {
      const data = getActivePanoramic(houseAndLandFlag)
      const active = data ? data.nameMap : panoramic[0]?.nameMap
      const panoramaType = data ? data.panoramaType : panoramic[0]?.panoramaType

      setActiveScene(active)
      setActivePanoramaType(panoramaType)
      setActiveSceneLoadingState(false)
    }
  }, [houseAndLandFlag, panoramic])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        panoramic: {
          pitch: pitchFirebase,
          yaw: yawFirebase,
          zoom: zoomFirebase,
          scene: sceneFirebase,
          rotationSpeed: rotationSpeedFirebase,
        },
      } = session

      setIsConnected(connected)
      setRotationSpeed(rotationSpeedFirebase)

      if (panoramic.length > 0) {
        setScene(sceneFirebase)
      }

      setZoom(zoomFirebase)
      setPitch(pitchFirebase)
      setYaw(yawFirebase)
    }
  }, [session])

  React.useEffect(() => {
    if (zoom !== undefined) {
      pannellumViewRef?.current?.pannellum.setHfov(zoom)
    }
  }, [zoom])

  React.useEffect(() => {
    if (pitch !== undefined) {
      pannellumViewRef?.current?.pannellum.setPitch(pitch)
    }
  }, [pitch])

  React.useEffect(() => {
    if (yaw !== undefined) {
      pannellumViewRef?.current?.pannellum.setYaw(yaw)
    }
  }, [yaw])

  React.useEffect(() => {
    if (rotationSpeed === 0) {
      pannellumViewRef?.current?.pannellum.stopAutoRotate()
      pannellumViewRef?.current?.pannellum.setPitch(pitch || 0)
      pannellumViewRef?.current?.pannellum.setYaw(yaw || 0)
      return
    }
    pannellumViewRef?.current?.pannellum.startAutoRotate(rotationSpeed || 0)
  }, [rotationSpeed, yaw, pitch])

  React.useEffect(() => {
    if (scene !== '') {
      pannellumViewRef?.current?.pannellum.loadScene(scene)
    }
  }, [scene])

  React.useEffect(
    () => () => {
      setHouseAndLandFlag(defaultHouseAndLandFlag)
      removeItem(PANORAMIC_ACTIVE_PRECINCT_KEY)
      removeItem(PANORAMIC_ACTIVE_STAGE_KEY)
      removeItem(PANORAMIC_ACTIVE_LOT_KEY)
    },
    []
  )

  return (
    <Container className="h-page-container w-screen">
      <DataHandler
        payload={{
          ...panoramicPayload,
          data: panoramic,
          apiData: panoramicPayload.panoramic,
        }}
        skeletonFrame={<Skeleton />}
      >
        {!isConnected && (
          <div className="absolute left-5 top-5 z-10">
            <Link
              to="stages"
              className="fixed rounded bg-white p-1 drop-shadow-40"
              title="Back to stages page"
            >
              <ArrowSvg className="h-8 w-8" strokeColor="#000" />
            </Link>
          </div>
        )}

        {isLoadingActiveScene ? (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent" />
          </div>
        ) : (
          <PannellumView
            key="panoramic-view"
            ref={pannellumViewRef}
            payload={panoramic}
            activeScene={activeScene}
            panoramaType={activePanoramaType}
            className="h-full w-full"
            hideHotspots={isConnected}
          />
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { projectName },
    panoramic,
    firestore,
    houseAndLand,
  }: RootStateFirebase) => ({
    projectName,
    panoramic,
    session: getSession(firestore),
    houseAndLand,
  })
)(HouseAndLand)
