import React from 'react'

import { PackageConfigurationInterface } from '@store/types'

import {
  PackageInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import SelectProperty from './select-property/select-property'

export interface LotPackageProps {
  packages: Array<PackageInterface>
  packageOptions: Array<PackageOptionsInterface>
  values: PackageConfigurationInterface
  onChange: (
    propertyType: keyof PackageConfigurationInterface,
    value: string
  ) => void
  onExpandProperty?: (data: {
    propertyType: keyof PackageConfigurationInterface
    expanded: boolean
  }) => void
}

const LotPackage = ({
  packages,
  packageOptions,
  values,
  onChange,
  onExpandProperty,
}: LotPackageProps) => {
  const [expandedProperty, setExpandedProperty] = React.useState<
    keyof PackageConfigurationInterface | undefined
  >('floorplan')
  const activeOption = React.useMemo(
    () => packageOptions.find((pkg) => pkg.id === values.floorplan),
    [packageOptions, values.floorplan]
  )
  const activeFacade = React.useMemo(
    () => activeOption?.facades?.find((facade) => facade.id === values.facade),
    [activeOption, values.facade]
  )
  const activeInternalTheme = React.useMemo(
    () =>
      activeOption?.internalThemes?.find(
        (internalTheme) => internalTheme.id === values.internalTheme
      ),
    [activeOption, values.internalTheme]
  )
  const valuesKey = React.useMemo(() => JSON.stringify(values), [values])

  const handleClick = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface) => () => {
      setExpandedProperty(
        expandedProperty === propertyType ? undefined : propertyType
      )
      onExpandProperty?.({
        propertyType,
        expanded: expandedProperty !== propertyType,
      })
    },
    [expandedProperty, onExpandProperty]
  )

  const handleChange = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface) => (value: string) => {
      onChange(propertyType, value)
    },
    [onChange]
  )

  return (
    <div className="counter-list flex flex-col gap-4" key={valuesKey}>
      <SelectProperty
        label="FLOORPLAN"
        options={packages}
        value={values?.floorplan || ''}
        onChange={handleChange('floorplan')}
        expandOptions={expandedProperty === 'floorplan'}
        onClick={handleClick('floorplan')}
        canDeselct
      />
      {activeOption?.floorplanOptions?.length ? (
        <SelectProperty
          key={`floorplanOption__${values?.floorplan}`}
          label="FLOORPLAN OPTION"
          options={activeOption?.floorplanOptions}
          value={values?.floorplanOption || ''}
          onChange={handleChange('floorplanOption')}
          expandOptions={expandedProperty === 'floorplanOption'}
          onClick={handleClick('floorplanOption')}
          canDeselct
        />
      ) : null}
      {activeOption?.facades?.length ? (
        <SelectProperty
          key={`facade__${values?.floorplan}`}
          label="FACADE"
          options={activeOption?.facades || []}
          value={values?.facade || ''}
          onChange={handleChange('facade')}
          expandOptions={expandedProperty === 'facade'}
          onClick={handleClick('facade')}
          canDeselct
          showAsThumbnail
        />
      ) : null}
      {activeFacade?.facadeColors?.length ? (
        <SelectProperty
          key={`facadeColor__${activeFacade?.id}`}
          label="FACADE COLOUR"
          options={activeFacade?.facadeColors || []}
          value={values?.facadeColor || ''}
          onChange={handleChange('facadeColor')}
          expandOptions={expandedProperty === 'facadeColor'}
          onClick={handleClick('facadeColor')}
          canDeselct
          showAsThumbnail
        />
      ) : null}
      {activeOption?.internalThemes?.length ? (
        <SelectProperty
          key={`internalTheme__${values?.floorplan}`}
          label="INTERNAL THEME"
          options={activeOption?.internalThemes || []}
          value={values?.internalTheme || ''}
          onChange={handleChange('internalTheme')}
          expandOptions={expandedProperty === 'internalTheme'}
          onClick={handleClick('internalTheme')}
          canDeselct
        />
      ) : null}
      {activeInternalTheme?.internalColorSchemes?.length ? (
        <SelectProperty
          key={`internalColorScheme__${activeInternalTheme?.id}`}
          label="INTERNAL COLOUR SCHEME"
          options={activeInternalTheme?.internalColorSchemes || []}
          value={values?.internalColorScheme || ''}
          onChange={handleChange('internalColorScheme')}
          expandOptions={expandedProperty === 'internalColorScheme'}
          onClick={handleClick('internalColorScheme')}
          canDeselct
        />
      ) : null}
    </div>
  )
}

export default LotPackage
