import React, { MouseEventHandler } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { BuildingInterface, setBuilding } from '@store/actionSlices/building'
import { RootStateFirebase } from '@store/types'

import { ArrowSvg } from '@svg/react'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-util'

interface LayerLinkProps {
  building: BuildingInterface
  label: string
  actionRoute: string
}

const LayerLink = ({ building, label, actionRoute }: LayerLinkProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { fullPath, path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )
  const navigateTo = React.useMemo(
    () => (path === 'building' || path === 'level-view' ? fullPath : path),
    [fullPath, path]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      history.push(navigateTo)
      dispatch(
        setBuilding({
          ...building,
          activeBlock: params.block || '',
          activeLevel: params.level || '',
          activeUnit: params.unit || '',
        })
      )
    },
    [navigateTo, params]
  )

  return isValid ? (
    <a href={navigateTo} onClick={handleClick}>
      <span className="border-b-2 border-black">{label}</span>
      <span className="border-b-2 border-black">
        <ArrowSvg
          size="m"
          className="-mr-1 inline-block h-5 w-5"
          styles={{ rotate: '135deg' }}
          strokeColor="#000000"
        />
      </span>
    </a>
  ) : (
    <span>{label}</span>
  )
}

export default connect(({ building }: RootStateFirebase) => ({
  building,
}))(LayerLink)
