import React from 'react'

import Skeleton from './skeleton'

interface ComponentProps {
  hasThumbnail?: boolean
}

const GallerySkeleton = ({ hasThumbnail }: ComponentProps) => (
  <Skeleton>
    <div className="absolute left-[1.625rem] top-8 z-20 flex flex-col gap-3">
      <div className="h-8 w-48 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="h-8 w-52 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="h-8 w-56 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="h-8 w-60 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="h-8 w-56 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
      <div className="h-8 w-64 rounded-full bg-gray-100/20">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    </div>

    {hasThumbnail && (
      <div className="absolute bottom-8 left-6 z-20 flex gap-3">
        <div className="h-20 w-28 rounded-lg bg-gray-100/30">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="h-20 w-28 rounded-lg bg-gray-100/30">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="h-20 w-28 rounded-lg bg-gray-100/30">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>
    )}
  </Skeleton>
)

export default GallerySkeleton
