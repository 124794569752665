import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useHistory, useLocation } from 'react-router'
import { compose } from 'redux'

import Routes from '@src/routes'

import { setSearchQuery } from '@store/actionSlices/appConfig'
import { setLoadingIndicatorVisibility } from '@store/actionSlices/loadingIndicator'
import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { TokenPayloadInterface, setToken } from '@store/actionSlices/token'
import type {
  MasterSessionKey,
  ProjectIdentity,
  ProjectSwitch,
  RootStateFirebase,
  SessionKey,
  SessionMap,
} from '@store/types'

import ConnectionRefused from '@pages/errors'

import { useRenewUserTokenMutation } from '@api/authentication'
import { ThemeConfigInterface } from '@api/config'
import {
  MasterKeyData,
  selectFromResult,
  useGetMasterKeyQuery,
} from '@api/master-key'

import useConnectionIndicator from '@utilities/connection-indicator'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import { setFirebaseQueryParam } from '@utilities/firebase-util'
import {
  getQueryStringParams,
  hasToken,
  initializeSentry,
} from '@utilities/helper'
import LocalStorageCleanerUtil from '@utilities/local-storage-cleaner-util'
import RefreshHandler from '@utilities/refresh-handler'
import ResetUtil from '@utilities/reset-util'
import styleUtil from '@utilities/style-util'

import { notifySuccess } from '@adUtilities/notifier'

interface FirebaseStateType extends RootStateFirebase {
  devsuite: SessionMap
  projectIdentity: ProjectIdentity
  token: TokenPayloadInterface
  isOnline: boolean
  theme: ThemeConfigInterface
  showcaseRefreshTriggerKey: string
}

const { REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS } = process.env

initializeSentry()

const App = ({
  devsuite,
  projectIdentity,
  token,
  isOnline,
  theme,
  showcaseRefreshTriggerKey,
}: FirebaseStateType) => {
  styleUtil(theme)

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const resetUtil = ResetUtil()

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
  })

  const [isIframe, setIsIframe] = React.useState(false)
  const [session, setSession] = React.useState<Array<string>>([])
  const [tokenRenewalIntervalId, setTokenRenewalIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()
  const [tokenRenewalState, setTokenRenewalState] = React.useState(false)
  const [userId, setUserId] = React.useState('')
  const [skipMasterKeyApi, setSkipMasterKeyApi] = React.useState(true)

  const masterKeyPayload = useGetMasterKeyQuery(
    {
      masterKey: projectIdentity.masterKey,
      userId,
    },
    {
      selectFromResult,
      skip: skipMasterKeyApi,
      pollingInterval:
        Number(REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS || 10) * 1000,
    }
  )

  const [
    renewUserToken,
    {
      isLoading: refreshTokenIsLoading,
      data: refreshTokenData,
      error: refreshTokenError,
    },
  ] = useRenewUserTokenMutation()

  const {
    connected,
    sessionOwners: firebaseSessionOwners,
    projectSwitch,
  } = devsuite || {}

  const { lightCode } = getQueryStringParams(location.search)

  useConnectionIndicator({ token, isOnline })

  LocalStorageCleanerUtil({ token })

  RefreshHandler({
    refreshTriggerKeyFirebase: devsuite?.showcaseRefreshTriggerKey ?? '',
    refreshTriggerKeyLocal: showcaseRefreshTriggerKey,
  })

  const handleTokenRenewalProcess = () => {
    if (!hasToken(token)) {
      return
    }

    const {
      access_token: { expires_at: expiresAt },
      refresh_token: { token: refreshToken },
      email,
    } = token

    const currentTime = new Date().getTime()
    const tokenExpiryTime = new Date(expiresAt).getTime()
    const timeDifference = tokenExpiryTime - currentTime
    const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24)
    if (timeDifferenceInDays <= 7) {
      renewUserToken({ refresh_token: refreshToken, email })
    }
  }

  const resetTokenRenewalInterval = () => {
    if (tokenRenewalIntervalId) {
      clearInterval(tokenRenewalIntervalId)
      setTokenRenewalIntervalId(undefined)
    }
  }

  const setTokenRenewalInterval = () => {
    if (!hasToken(token)) {
      resetTokenRenewalInterval()
      return
    }

    if (!tokenRenewalIntervalId) {
      setTokenRenewalIntervalId(
        setInterval(() => setTokenRenewalState(true), 1000 * 86400)
      )
    }
  }

  const handleAppId = (appIdleKey: string) => {
    if (appIdleKey) {
      firebaseControlQuery.update({
        [`appIdleKey`]: '',
      })
    }
  }

  const handleClientCheck = (client: boolean) => {
    if (!client) {
      firebaseControlQuery.update({
        [`client.showcase`]: true,
      })
    }
  }

  const handleMasterSessionKeys = React.useCallback(
    (argMasterKeyData: MasterKeyData) => {
      const { sessionKeys: masterSessionKeys } = argMasterKeyData

      if (masterSessionKeys.length < 1) {
        return
      }

      const sessionId: Array<SessionKey> = masterSessionKeys.map(
        (item: MasterSessionKey) => ({
          key: item.key,
          project: item.projectId,
        })
      )

      dispatch(setProjectIdentity({ ...projectIdentity, sessionId }))
    },
    [masterKeyPayload]
  )

  React.useEffect(() => {
    if (!devsuite) {
      return
    }
    if (!connected) {
      setSession([])
      return
    }

    firebaseSessionOwners.forEach((res) => {
      if (!session.find((ses: string) => res.name === ses)) {
        if (res.name && res.email && session) {
          notifySuccess(`${res.name} is Connected`)
          setSession([...session, res.name])
        }
      }
    })
  }, [firebaseSessionOwners])

  const handleRemoteProjectSwitch = (
    { projectName, projectId, projectLabel }: ProjectSwitch,
    activeRoute?: string
  ) => {
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        projectName,
        projectId,
        projectLabel,
      })
    )
    resetUtil.resetLocalStorage()
    history.replace({ pathname: `/${projectName}/${activeRoute}` })
    window.location.reload()
  }

  React.useEffect(() => {
    if (lightCode) {
      dispatch(setProjectIdentity({ lightMap: lightCode }))
    }
  }, [lightCode])

  React.useEffect(() => {
    const { sessionId } = projectIdentity
    if (!projectSwitch) {
      return
    }

    const { projectName, projectLabel, projectId } = projectSwitch

    if (!sessionId.find((res) => res.project === projectName)) {
      return
    }

    if (projectName && projectLabel && projectId) {
      handleRemoteProjectSwitch(projectSwitch, devsuite?.activeRoute)
    }
  }, [projectSwitch])

  React.useEffect(() => {
    dispatch(setLoadingIndicatorVisibility(refreshTokenIsLoading))
  }, [refreshTokenIsLoading])

  React.useEffect(() => {
    if (refreshTokenData && refreshTokenData.data) {
      const {
        data: { user },
      } = refreshTokenData
      dispatch(setToken(user as TokenPayloadInterface))
    }
  }, [refreshTokenData])

  React.useEffect(() => {
    if (refreshTokenError) {
      console.error('Failed to renew the user token.', refreshTokenError)
    }
  }, [refreshTokenError])

  React.useEffect(() => {
    setTokenRenewalInterval()
  }, [token])

  React.useEffect(() => {
    if (tokenRenewalState) {
      handleTokenRenewalProcess()
      setTokenRenewalState(false)
    }
  }, [tokenRenewalState])

  React.useEffect(() => {
    if (hasToken(token) && projectIdentity.masterKey) {
      const { _id } = token
      setUserId(_id)
      setSkipMasterKeyApi(false)
    } else {
      setSkipMasterKeyApi(true)
    }
  }, [token, projectIdentity])

  React.useEffect(() => {
    const { masterKeyData, isError } = masterKeyPayload
    if (!isError && Object.keys(masterKeyData).length > 0) {
      handleMasterSessionKeys(masterKeyData)
    }
  }, [masterKeyPayload])

  React.useEffect(() => {
    setIsIframe(window.self !== window.top)
  }, [])

  React.useEffect(() => {
    const { searchQuery } = getQueryStringParams(location.search)
    if (searchQuery) {
      dispatch(setSearchQuery(searchQuery === 'true'))
    }
  }, [])

  React.useEffect(() => {
    if (devsuite) {
      const { appIdleKey, client } = devsuite

      handleAppId(appIdleKey)
      handleClientCheck(client?.showcase)
    }
  }, [devsuite])

  if (isIframe) {
    return <ConnectionRefused />
  }

  return <Routes {...{ session: devsuite, projectIdentity, token }} />
}

export default compose(
  connect(
    ({
      projectIdentity,
      firestore: {
        data: { devsuite },
      },
      token,
      connectionIndicator: { isOnline },
      projectConfig: { theme, showcaseRefreshTriggerKey },
    }: RootStateFirebase) => ({
      devsuite,
      projectIdentity,
      token,
      isOnline,
      theme,
      showcaseRefreshTriggerKey,
    })
  ),
  firestoreConnect(({ projectIdentity }: RootStateFirebase) =>
    setFirebaseQueryParam(projectIdentity)
  )
)(App) as React.ComponentType
