import React from 'react'
import { CSSTransition } from 'react-transition-group'

import { CheckmarkCircleSvg } from '@src/components/adgroup-svg/react'

import { PropertyOption as PropertyOptionType } from '@pages/stage/lots-side-panel/lot-card/types'

import AssetHandler from '@utilities/asset-handler'
import { formatPrice } from '@utilities/helper'

type OptionalFields = 'price' | 'images' | 'thumbnail'

export type Option = Omit<PropertyOptionType, OptionalFields> &
  Partial<Pick<PropertyOptionType, OptionalFields>>

interface PropertyOptionProps {
  isSelected: boolean
  option: Option
  onClick: () => void
  showAsThumbnail: boolean
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

const PropertyOption = ({
  isSelected,
  option,
  onClick,
  showAsThumbnail,
}: PropertyOptionProps) =>
  showAsThumbnail ? (
    <div
      className="relative h-[60px] cursor-pointer overflow-hidden rounded"
      onClick={onClick}
      role="none"
    >
      <CSSTransition
        in={isSelected}
        timeout={500}
        classNames="transition-fade-ease"
      >
        {isSelected ? (
          <>
            <div className="absolute inset-0 bg-black/60"></div>
            <CheckmarkCircleSvg className="absolute bottom-1 right-1 h-[16px] w-[16px] invert" />
          </>
        ) : (
          <></>
        )}
      </CSSTransition>
      {option.price ? (
        <span className="absolute bottom-1 left-1 text-[13px] font-medium text-white opacity-70">
          +{formatPrice(option.price)}
        </span>
      ) : null}
      <img
        className="h-full w-full object-cover object-cover"
        alt="thumbnail"
        src={AssetHandler({
          url: String(
            option?.thumbnail?.url ||
              option.images?.[0]?.url ||
              PLACEHOLDER_IMAGE
          ),
          type: 'new',
          staticUrl: true,
        })}
      />
    </div>
  ) : (
    <div
      className={`col-span-2 cursor-pointer rounded px-4 py-2 transition-all duration-300 ${
        isSelected ? 'bg-mainColour' : 'bg-zinc-200'
      }`}
      onClick={onClick}
      role="none"
    >
      <div
        className={`flex items-center justify-between gap-1 transition-all duration-300 ${
          isSelected ? 'text-white' : ''
        } font-medium`}
      >
        <span>{option.name}</span>
        {option.price ? (
          <span className="text-[13px] opacity-70">
            +{formatPrice(option.price)}
          </span>
        ) : null}
      </div>
    </div>
  )

export default PropertyOption
