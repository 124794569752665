import React from 'react'

import { CloseMarkSvg } from '@svg/react'

interface GenericModalCardProps {
  toggle: (arg: boolean) => void
  cancelAction?: () => void
  okAction?: () => void
  isVisible: boolean
  children: React.ReactNode
  cancelLabel?: string
  okLabel?: string
  headerLabel?: string
  disableOk?: boolean
  hideOk?: boolean
  hideCancel?: boolean
  widthClassName?: string
  headerClassName?: string
  closeClassName?: string
  footerClassName?: string
  cancelClassName?: string
  okClassName?: string
}

const GenericModalCard = ({
  headerLabel,
  cancelLabel = 'Cancel',
  okLabel = 'Ok',
  toggle,
  isVisible,
  cancelAction,
  okAction,
  children,
  disableOk,
  hideOk,
  hideCancel,
  widthClassName,
  headerClassName,
  closeClassName,
  footerClassName,
  cancelClassName,
  okClassName,
}: GenericModalCardProps) => {
  const closeModal = () => toggle(false)

  const handleGenericClose = () => {
    if (cancelAction) {
      cancelAction()
    }
    closeModal()
  }

  const handleGenericOk = () => {
    if (okAction) {
      okAction()
    }
    setTimeout(() => {
      closeModal()
    }, 200)
  }

  return (
    <div
      className={`absolute left-0 top-0 z-50 flex w-screen items-center overflow-hidden bg-opacity-50  transition-all ease-in-out ${
        isVisible ? 'h-screen duration-100' : 'h-0  duration-700'
      }`}
    >
      <div
        className={`absolute left-0 top-0 flex h-screen w-screen items-center bg-gray-700 bg-opacity-70 transition-all  ease-in-out ${
          isVisible ? 'opacity-100  duration-700' : 'opacity-0 duration-100'
        }`}
      >
        <div
          className={`m-auto ${
            widthClassName ?? 'w-1/4'
          } rounded bg-white pt-5 drop-shadow-md transition-all  duration-300 ease-in-out ${
            isVisible ? 'translate-y-0' : '-translate-y-full scale-0'
          }
        `}
        >
          <div className="flex border-b border-gray-300 px-3 pb-2">
            <b className={headerClassName}>{headerLabel}</b>
            <button
              onClick={() => closeModal()}
              type="button"
              className={closeClassName ?? 'ml-auto'}
            >
              <CloseMarkSvg stroke={2} />
            </button>
          </div>
          <div className="border-gray-300 py-5 text-center">{children}</div>
          <div
            className={
              footerClassName ??
              'flex justify-end gap-2 border-t border-gray-300 p-4'
            }
          >
            {!hideCancel && (
              <button
                type="button"
                className={cancelClassName ?? 'rounded bg-gray-300 px-3 py-2'}
                onClick={() => handleGenericClose()}
              >
                {cancelLabel}
              </button>
            )}
            {!hideOk && (
              <button
                onClick={() => handleGenericOk()}
                type="button"
                className={
                  okClassName ??
                  `rounded ${
                    disableOk ? 'bg-blue-500/60' : 'bg-blue-500'
                  }  px-3 py-2 text-white transition-all duration-300`
                }
                disabled={disableOk}
              >
                {okLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericModalCard
