import React from 'react'

import { ExclamationTriangleSvg } from '@svg/react'

export interface NoDataFoundProps {
  message?: string
}

const DataNotFound = ({ message = 'No data found.' }: NoDataFoundProps) => (
  <div className="h-screen">
    <div className="flex h-full">
      <div className="flex w-1/2 items-center justify-center bg-mainColour">
        <ExclamationTriangleSvg className="h-32 w-32 text-white" />
      </div>
      <div className="flex w-1/2 items-center bg-white">
        <div className="ml-8">
          <span className="text-5xl font-bold">400</span>
          <p className="mt-2 text-2xl">{message}</p>
        </div>
      </div>
    </div>
  </div>
)

export default DataNotFound
