import { CarouselProvider } from 'pure-react-carousel'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { DesignInterface, setDesign } from '@store/actionSlices/design'
import type { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FadeContainer from '@components/fade-container'
import GalleryHandler, { SlideTrigger } from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import { GallerySkeleton } from '@components/skeleton'

import { GalleryData, selectFromResult, useGetDesignQuery } from '@api/design'

import getSession from '@utilities/firebase-util'

import { generateId } from '@adUtilities/generate-id-util'

export interface DeisgnProps {
  session: SessionMap | undefined
  projectName: string
  design: Array<DesignInterface>
}

const Design = ({ session, projectName, design }: DeisgnProps) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const [toggleControls, setToggleControls] = React.useState(true)
  const [activeLabel, setActiveLabel] = React.useState(0)
  const [isConnected, setIsConnected] = React.useState(false)
  const [transitionEffectId, setTransitionEffectId] = React.useState(
    generateId(10)
  )
  const [transitionTime, setTransitionTime] = React.useState(1000)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const designPayload = useGetDesignQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    const { gallery } = designPayload

    if (design.length === 0 && gallery.length > 0) {
      dispatch(setDesign(gallery))
    }
  }, [designPayload])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        design: {
          galleryControl: {
            activeTabIndex: activeTabIndexFirebase,
            transitionTime: transitionTimeFirebase,
            isPlaying: isPlayingFirebase,
          },
        },
      } = session
      setIsConnected(connected)
      setActiveLabel(activeTabIndexFirebase)
      setTransitionTime(transitionTimeFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  React.useEffect(() => {
    setTransitionEffectId(generateId(10))
  }, [activeLabel])

  const handleGalleryHandlerClick = () => {
    setToggleControls((state) => !state)
  }

  const carouselData = React.useMemo(
    () =>
      design.length > 0
        ? {
            slides: design[activeLabel].items.map((res: GalleryData) => ({
              id: res.id,
              src: res.imageSource,
            })),
            labels: design[activeLabel].items.map(
              (res: GalleryData, index) => ({
                label: res.title,
                sublabel: `sublabel-${index}`,
                src: res.imageSource,
                type: 'legacy',
                noSpliceUrl: false,
                gradiant: 0,
              })
            ),
          }
        : { slides: [], labels: [] },
    [design, activeLabel]
  )

  return (
    <Container>
      <DataHandler
        payload={{
          ...designPayload,
          data: design,
          apiData: designPayload.gallery,
        }}
        skeletonFrame={<GallerySkeleton hasThumbnail />}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={design.length}
          dragEnabled={false}
          orientation="vertical"
          currentSlide={activeLabel}
          className="ipad-mini:w-1100px"
          interval={transitionTime}
          isPlaying={isSlideShowPlaying}
          disableAnimation
          disableKeyboard
          infinite
        >
          {!isConnected && (
            <IdleTimeHandler>
              <FadeContainer className="relative z-10" show={toggleControls}>
                <div className="absolute flex pt-8">
                  <SlideTrigger
                    type="text"
                    items={design.map((content) => ({ text: content.label }))}
                    setCurrentSlide={setActiveLabel}
                    currentSlide={activeLabel}
                    dotContainerRef={dotContainerRef}
                    toggleControls={toggleControls}
                  />
                </div>
              </FadeContainer>
            </IdleTimeHandler>
          )}
        </CarouselProvider>

        <TransitionGroup className="h-full">
          <CSSTransition
            key={transitionEffectId}
            classNames="transition-fade"
            timeout={500}
          >
            <GalleryHandler
              galleryName="design"
              galleryControl={session?.design.galleryControl}
              slides={carouselData.slides}
              labels={carouselData.labels}
              isConnected={isConnected}
              dotType="thumbnail"
              onClick={handleGalleryHandlerClick}
              toggleControls={toggleControls}
              toggleBlurredBackground
            />
          </CSSTransition>
        </TransitionGroup>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    design,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    design,
  })
)(Design)
