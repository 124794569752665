import React from 'react'

import {
  AreaSvg,
  BathSvg,
  BedSvg,
  CarSvg,
  FrontageSvg,
  PowderSvg,
  StudySvg,
} from '@components/adgroup-svg/react'

import {
  ConfigurationInterface,
  LotInterface,
} from '@api/types/house-and-land-type'

type LotConfigurationProps = Partial<ConfigurationInterface> &
  Pick<LotInterface, 'area' | 'frontage'>

const LotConfiguration = ({
  bed,
  study,
  bath,
  powderRoom,
  car,
  area,
  frontage,
}: LotConfigurationProps) => (
  <div className="flex flex-col gap-2 text-zinc-500">
    <div className="flex gap-3">
      {Number(bed) > 0 && (
        <div className="inline-flex items-center gap-1" title="Bed">
          <BedSvg className="h-[17px] w-[17px]" />
          <span>{bed}</span>
        </div>
      )}
      {Number(study) > 0 && (
        <div className="inline-flex items-center gap-1" title="Study">
          <StudySvg className="h-[17px] w-[17px]" />
          <span>{study}</span>
        </div>
      )}
      {Number(bath) > 0 && (
        <div className="inline-flex items-center gap-1" title="Bath">
          <BathSvg className="h-[17px] w-[17px]" />
          <span>{bath}</span>
        </div>
      )}
      {Number(powderRoom) > 0 && (
        <div className="inline-flex items-center gap-1" title="Powder">
          <PowderSvg className="h-[17px] w-[17px]" />
          <span>{powderRoom}</span>
        </div>
      )}
      {Number(car) > 0 && (
        <div className="inline-flex items-center gap-1" title="Car">
          <CarSvg className="h-[17px] w-[17px]" />
          <span>{car}</span>
        </div>
      )}
    </div>
    <div className="flex gap-3">
      {Number(area) > 0 && (
        <div className="inline-flex items-center gap-1" title="Car">
          <AreaSvg className="h-[17px] w-[17px]" />
          <span>{area}sqm</span>
        </div>
      )}
      {Number(frontage) > 0 && (
        <div className="inline-flex items-center gap-1" title="Car">
          <FrontageSvg className="h-[17px] w-[17px]" />
          <span>{frontage}m</span>
        </div>
      )}
    </div>
  </div>
)

export default LotConfiguration
