import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@src/store/types'

import {
  ConfigurationInterface,
  LotInterface,
  LotStatusType,
  PackageInterface,
  PrecinctListItemInterface,
} from '@api/types/house-and-land-type'

import { formatPrice } from '@utilities/helper'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  ChevronSvg,
  CompassRegularSvg,
  MinusSvg,
  PlusSvg,
  PowderSvg,
  StudySvg,
} from '@svg/react'

const AREA_UNIT = 'sqm'
const FRONTAGE_UNIT = 'm'

interface ComponentPropsInterface {
  lot: LotInterface
  packageInfo: PackageInterface | undefined
  precinctList: Array<PrecinctListItemInterface>
  totalPrice: number
  connected: boolean
  activePrecinctId: string
  activeStageId: string
  showPrice: boolean
  hideSoldPrice: boolean
  hideUnitInfoName: boolean
  hideInfoPanelAspect: boolean
  handlePackageInfoModal: () => void
}

const PackageInfoCard = ({
  lot,
  packageInfo,
  precinctList,
  totalPrice,
  connected,
  activePrecinctId,
  activeStageId,
  showPrice,
  hideSoldPrice,
  hideUnitInfoName,
  hideInfoPanelAspect,
  handlePackageInfoModal,
}: ComponentPropsInterface) => {
  const [showDetail, setShowDetailState] = React.useState(false)

  const configuration: ConfigurationInterface = React.useMemo(() => {
    const output = {
      bed: 0,
      study: 0,
      bath: 0,
      powderRoom: 0,
      car: 0,
    }

    if (!packageInfo) {
      return output
    }

    const { configuration: packageConfig } = packageInfo

    return {
      bed: Number(packageConfig?.bed || 0),
      study: Number(packageConfig?.study || 0),
      bath: Number(packageConfig?.bath || 0),
      powderRoom: Number(packageConfig?.powderRoom || 0),
      car: Number(packageConfig?.car || 0),
    }
  }, [packageInfo])

  const canWeShowPrice: boolean = React.useMemo(
    () => showPrice && !(lot.status === LotStatusType.Sold && hideSoldPrice),
    [showPrice, lot, hideSoldPrice]
  )

  return (
    <div
      className={`rounded-3xl bg-neutral-600 px-6 pt-6 transition-all delay-150 duration-300 ${
        showDetail ? 'pb-2' : 'pb-6'
      }`}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between">
          <div>
            {activePrecinctId && precinctList.length > 1 && (
              <div className="text-lg font-bold uppercase text-white">
                {activePrecinctId}
              </div>
            )}
            {!hideUnitInfoName && (
              <div className="text-3xl font-bold text-white">
                {packageInfo?.name || lot.name}
              </div>
            )}
            {totalPrice > 0 && canWeShowPrice && (
              <div className="text-2xl font-medium text-white">
                {formatPrice(totalPrice)}
              </div>
            )}
          </div>
          {!connected && (
            <button
              type="button"
              onClick={() => setShowDetailState(!showDetail)}
              className={`inline-flex h-8 w-8 transform items-center justify-center rounded-full bg-zinc-400 text-white transition-transform duration-700 ease-in-out ${
                showDetail ? 'rotate-180' : ''
              }`}
            >
              {!showDetail ? (
                <MinusSvg className="h-5 w-5" stroke={2} />
              ) : (
                <PlusSvg className="h-6 w-6" stroke={2} />
              )}
            </button>
          )}
        </div>

        <div className="flex justify-between space-x-6 rounded-2xl bg-zinc-900 px-6 py-4 text-base text-white empty:hidden">
          {configuration.bed > 0 && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Bed"
            >
              <BedSvg className="h-6 w-6" />
              <span>{configuration.bed}</span>
            </div>
          )}
          {configuration.study > 0 && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Study"
            >
              <StudySvg className="h-6 w-6" />
              <span>{configuration.study}</span>
            </div>
          )}
          {configuration.bath > 0 && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Bath"
            >
              <BathSvg className="h-6 w-6" />
              <span>{configuration.bath}</span>
            </div>
          )}
          {configuration.powderRoom > 0 && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Powder"
            >
              <PowderSvg className="h-6 w-6" />
              <span>{configuration.powderRoom}</span>
            </div>
          )}
          {configuration.car > 0 && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Car"
            >
              <CarSvg className="h-6 w-6" />
              <span>{configuration.car}</span>
            </div>
          )}
          {lot.aspect && (
            <div
              className="inline-flex items-center space-x-1 text-white"
              title="Aspect"
            >
              <CompassRegularSvg fill="#ffffff" className="h-7 w-7" />
              <span>{lot.aspect}</span>
            </div>
          )}
        </div>
        <div
          className={`grid grid-flow-col grid-rows-3 gap-2 overflow-hidden text-xl text-white transition-all delay-150 duration-300 ${
            showDetail ? 'h-0' : 'h-24'
          }`}
        >
          <div>
            Lot: <span className="font-bold">{lot.name}</span>
          </div>
          {lot.area && (
            <div>
              Lot Area:{' '}
              <span className="font-bold">{`${lot.area}${AREA_UNIT}`}</span>
            </div>
          )}
          {!hideInfoPanelAspect && lot.aspect && (
            <div>
              Aspect: <span className="font-bold">{lot.aspect || 'N/A'}</span>
            </div>
          )}
          <div>
            Stage: <span className="font-bold">{activeStageId || ''}</span>
          </div>
          <div>
            Lot Frontage:{' '}
            <span className="font-bold">{`${lot.frontage}${FRONTAGE_UNIT}`}</span>
          </div>
          {configuration.study > 0 && (
            <div>
              Study: <span className="font-bold">{configuration.study}</span>
            </div>
          )}
          <div className="hidden">
            <button
              type="button"
              className="inline-flex cursor-pointer items-center underline underline-offset-2"
              onClick={handlePackageInfoModal}
            >
              <span>Show inclusions</span>
              <ChevronSvg rotate="right" className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectConfig: {
      showPrice,
      hideSoldPrice,
      hideUnitInfoName,
      hideInfoPanelAspect,
    },
    houseAndLand: { activePrecinctId, activeStageId, precinctList },
  }: RootStateFirebase) => ({
    showPrice,
    hideSoldPrice,
    hideUnitInfoName,
    hideInfoPanelAspect,
    activePrecinctId,
    activeStageId,
    precinctList,
  })
)(PackageInfoCard)
