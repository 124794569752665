import React from 'react'

import { SvgProps } from './types'

const ExclamationTriangleSvg = ({
  width,
  height,
  className,
  stroke,
}: SvgProps) => (
  <svg
    className={className}
    width={width || '48'}
    height={height || '48'}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={stroke || 1.5}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    />
  </svg>
)

export default ExclamationTriangleSvg
