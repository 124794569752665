import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  BuildingInterface,
  setBuilding,
  setByFlag,
} from '@store/actionSlices/building'
import { setInteractivePlan } from '@store/actionSlices/interactivePlan'
import {
  setMarkerColour,
  setProjectConfig,
} from '@store/actionSlices/projectConfig'
import type { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import ImageHandler from '@components/image-handler'
import { CanvasInteractive } from '@components/showcase-canvas'
import { CanvasRefInterface } from '@components/showcase-canvas/canvas-interactive'
import { Polygon } from '@components/showcase-canvas/types'

import {
  selectFromResult as selectFromBuildingResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectFromResultConfig,
  useGetConfigQuery,
} from '@api/config'
import {
  InteractivePlanData,
  MapContent,
  selectFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import getSession from '@utilities/firebase-util'
import styleUtil from '@utilities/style-util'

import AreaSkeleton from './area-skeleton'

export interface AreaProps {
  session: SessionMap | undefined
  projectName: string
  interactivePlan: InteractivePlanData
  building: BuildingInterface
  areaMarkerColour: string
}

const ARROW_TYPE = 'arrow'
const ARROW_SMALL_TYPE = 'arrow-small'

const Area = ({
  session,
  projectName,
  interactivePlan,
  building,
  areaMarkerColour,
}: AreaProps) => {
  const themeData = styleUtil()
  const history = useHistory()
  const dispatch = useDispatch()

  const [isBuildingLoading, setBuildingLoadingState] = React.useState(true)
  const [isCanvasImageLoaded, setImageLoaded] = React.useState(false)

  const canvasContainerRef = React.useRef<HTMLDivElement>(null)
  const canvasRef = React.useRef<CanvasRefInterface>()
  const [renderCanvas, setRenderCanvas] = React.useState(false)
  const [theme, setTheme] = React.useState({
    font: '',
    mainColour: '',
  })
  const [area, setArea] = React.useState<MapContent>({
    image: '',
    polygons: [],
  })
  const [isConnected, setIsConnected] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName },
    { selectFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName },
    { selectFromResult: selectFromBuildingResult }
  )

  const { configInfo, markerColour, isLoaded } = useGetConfigQuery(
    { projectName },
    { selectFromResult: selectFromResultConfig }
  )

  React.useEffect(() => {
    if (configInfo && Object.keys(configInfo).length > 0) {
      dispatch(setProjectConfig({ ...configInfo }))
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (markerColour && Object.keys(markerColour).length > 0) {
      dispatch(setMarkerColour(markerColour))
    }
  }, [isLoaded])

  const getLabel = (poly: Polygon) => {
    const POLY_TYPE = poly.type || ''
    if (POLY_TYPE === ARROW_TYPE || POLY_TYPE === ARROW_SMALL_TYPE) {
      return poly.label || poly.groupId
    }
    return poly.label
  }

  const setInteractiveAction = (areaViewMap: MapContent) => {
    const areaData = {
      ...areaViewMap,
      polygons: areaViewMap.polygons.map((poly) => ({
        ...poly,
        label: getLabel(poly),
        onClick: () => {
          dispatch(
            setByFlag({
              flag: 'activeBlock',
              value: poly.groupId,
            })
          )
          if (!isConnected) {
            history.push('level-view')
          }
        },
        markerColour: areaMarkerColour,
      })),
    }
    return areaData
  }

  React.useEffect(() => {
    setRenderCanvas(true)
  }, [])

  React.useEffect(() => {
    if (interactivePlan.areaView?.image) {
      setArea(setInteractiveAction(interactivePlan.areaView))
    }
  }, [interactivePlan, isConnected])

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (!interactivePlan.areaView?.image && maps.areaView) {
      dispatch(setInteractivePlan(maps))
    }
  }, [interactivePayload, interactivePlan])

  React.useEffect(() => {
    const {
      building: buildingData,
      isLoaded: isBuildingLoaded,
      blockOrders: blockOrdersData,
    } = buildingPayload
    if (building.levels.length === 0 && buildingData.length > 0) {
      dispatch(
        setBuilding({
          ...building,
          blockOrders: blockOrdersData,
          levels: buildingData,
          activeLevel: buildingData[0]?.level,
        })
      )
    }
    setBuildingLoadingState(!isBuildingLoaded)
  }, [buildingPayload, building])

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        areaView: { triggerBuilding },
        building: { activeBlock },
      } = session
      if (activeBlock) {
        dispatch(
          setByFlag({
            flag: 'activeBlock',
            value: activeBlock,
          })
        )
      }

      if (triggerBuilding && renderCanvas) {
        let polyToTrigger: string | undefined =
          area?.polygons[0]?.groupId || 'BuildingGroup'
        if (activeBlock) {
          const foundItem = area?.polygons.find(
            (res) => activeBlock === res.groupId
          )?.groupId
          if (foundItem) {
            polyToTrigger = foundItem
          }
        }
        canvasRef?.current?.artificialTrigger(polyToTrigger || 'BuildingGroup')
      }
      setIsConnected(connected)
    }
  }, [session])

  React.useEffect(() => {
    const themeFromStorage = JSON.parse(
      localStorage.getItem('themeObject') || '{}'
    )
    if (themeFromStorage) {
      setTheme(themeFromStorage)
    }
  }, [])

  const loadAreaView = React.useMemo(() => {
    if (interactivePlan?.areaView?.image) {
      return true
    }
    return false
  }, [interactivePlan?.areaView?.image])

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePayload,
          data: loadAreaView && !isBuildingLoading,
        }}
        skeletonFrame={<AreaSkeleton />}
      >
        {area.polygons.length > 0 && (
          <>
            <ImageHandler
              key={area.image}
              url={area.image}
              type="new"
              className="background-cover image-blur absolute inset-0 z-2"
              noSpliceUrl
              showFallbackImage={false}
              bgProps={{
                gradiant: 0.5,
              }}
            />
            <div className="absolute inset-0 z-3">
              <div
                key="area-canvas"
                data-testid="area-canvas"
                ref={canvasContainerRef}
                className={`relative flex h-full w-full items-center justify-center overflow-hidden ${
                  isCanvasImageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <CanvasInteractive
                  ref={canvasRef}
                  id="area-canvas"
                  canvasData={area}
                  parentRef={canvasContainerRef}
                  theme={{
                    brandColour:
                      theme?.mainColour || themeData.mainColour || '',
                    font: theme?.font || themeData.font || '',
                  }}
                  adjustCanvasSizeWithContainer
                  setImageLoaded={setImageLoaded}
                />
              </div>
            </div>
          </>
        )}
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity: { projectName },
    interactivePlan,
    building,
    projectConfig: {
      markerColour: { area: areaMarkerColour },
    },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName,
    interactivePlan,
    building,
    areaMarkerColour,
  })
)(Area)
