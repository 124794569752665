import {
  Configuration,
  FilterOptionLotConfigurations,
  FilterOptions,
  FilterOptionsConfigurations,
  Lot,
  LotStatusType,
  MinMaxInterface,
  Package,
  PackageSummary,
  Stage,
  Stages,
} from './types/houseAndLand'

const ANY = 'Any'

const isValueInRange = (range: MinMaxInterface, value: number): boolean => {
  const { min, max } = range

  if (min === ANY && max === ANY) {
    return true
  }
  if (min === ANY) {
    return value <= Number(max)
  }
  if (max === ANY) {
    return value >= Number(min)
  }

  return value >= Number(min) && value <= Number(max)
}

const isConfigMatch = (
  pkgConfig: Configuration | undefined,
  filterConfig: FilterOptionsConfigurations | undefined
): boolean => {
  if (!pkgConfig || !filterConfig) {
    return true
  }

  const configFields: Array<keyof FilterOptionsConfigurations> = [
    'bed',
    'study',
    'bath',
    'powderRoom',
    'car',
  ]

  return configFields.every((field) => {
    const configValue = filterConfig[field]

    if (!configValue) {
      return true
    }

    const pkgValue = Number(pkgConfig?.[field] ?? 0)
    return isValueInRange(configValue, pkgValue)
  })
}

const isBuilderIncluded = (
  pkgBuilder: string | undefined,
  filterBuilderList: Array<string> | undefined
): boolean => {
  if (!pkgBuilder || !filterBuilderList?.length) {
    return true
  }

  return filterBuilderList.some(
    (b) => b.toLowerCase() === pkgBuilder.toLowerCase()
  )
}

const isStoreyIncluded = (
  pkgStorey: number | undefined,
  filterStoreyList: Array<number> | undefined
): boolean => {
  if (!pkgStorey || !filterStoreyList?.length) {
    return true
  }

  return filterStoreyList.indexOf(pkgStorey) !== -1
}

const filterPackages = (
  packages: Array<Package>,
  filterOptions: FilterOptions
): Package[] => {
  const { configurations, builder, storey } = filterOptions

  return packages.filter((pkg) => {
    const matchesConfiguration = isConfigMatch(
      pkg.configuration,
      configurations
    )
    const matchesBuilder = isBuilderIncluded(pkg.builder, builder)
    const matchesStorey = isStoreyIncluded(pkg.storey, storey)

    return matchesConfiguration && matchesBuilder && matchesStorey
  })
}

const isLotConfigMatch = (
  lot: Lot,
  filterLotConfig: FilterOptionLotConfigurations | undefined
): boolean => {
  const { frontage, area, aspects, price } = filterLotConfig || {}

  const matchesLotFrontage =
    frontage && lot?.frontage ? isValueInRange(frontage, lot.frontage) : true

  const matchesLotArea =
    area && lot.area ? isValueInRange(area, Number(lot.area)) : true

  const matchAspect =
    aspects && aspects.length && lot?.aspect
      ? aspects.some((a) => a.toLocaleLowerCase() === lot.aspect.toLowerCase())
      : true

  const matchesPrice =
    price && lot?.price ? isValueInRange(price, lot.price) : true

  return matchesLotFrontage && matchesLotArea && matchAspect && matchesPrice
}

const filterLots = (
  lots: Array<Lot>,
  filteredLotIds: Array<string>,
  filterOptions: FilterOptions
): Array<Lot> =>
  lots
    .filter((lot: Lot) => filteredLotIds.indexOf(lot.id) !== -1)
    .filter(
      (lot) =>
        !filterOptions.showOnlyAvailable ||
        lot.status === LotStatusType.Available
    )
    .filter((lot) => isLotConfigMatch(lot, filterOptions.lotConfiguration))

const getUniqueLotIds = (filteredPackages: Array<Package>): Array<string> =>
  filteredPackages
    .flatMap((pkg: Package) => pkg.lots)
    .filter(
      (lotId: string, index: number, lots: Array<string>) =>
        lots.indexOf(lotId) === index
    )

const filterLotsWithinStages = (
  stages: Stages,
  filteredLotIds: Array<string>,
  filterOptions: FilterOptions
): Stages =>
  Object.entries(stages).reduce(
    (accumulator: Stages, [stageKey, stageValue]: [string, Stage]) => {
      const filteredLots = filterLots(
        stageValue.lots,
        filteredLotIds,
        filterOptions
      )

      if (filteredLots.length > 0) {
        accumulator[stageKey] = {
          ...stageValue,
          lots: filteredLots,
        }
      }

      return accumulator
    },
    {} as Stages
  )

const filterPackageSummary = (
  packageSummary: PackageSummary,
  filterOptions: FilterOptions
): PackageSummary | null => {
  const { packages, stages } = packageSummary

  const filteredPackages: Array<Package> = filterPackages(
    packages,
    filterOptions
  )

  if (!filteredPackages.length) {
    return null
  }

  const filteredLotIds: Array<string> = getUniqueLotIds(filteredPackages)

  const filteredStages = filterLotsWithinStages(
    stages,
    filteredLotIds,
    filterOptions
  )

  return { packages: filteredPackages, stages: filteredStages }
}

export default filterPackageSummary
