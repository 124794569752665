import { ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel'
import React from 'react'

import { GalleryControlInterface, LotGallery, SessionMap } from '@store/types'

import FadeContainer from '@components/fade-container'
import {
  GallerySlide,
  GallerySlider,
  SlideTrigger,
} from '@components/gallery-handler'
import IdleTimeHandler from '@components/idle-time-handler'
import ImageHandler from '@components/image-handler'
import Player from '@components/player'

import AssetHandler from '@utilities/asset-handler'
import handleMouseWheel from '@utilities/gallery-image-control-util'

export interface LotCarouselContentInterface {
  gallery: Array<LotGallery>
  session: SessionMap | undefined
  isConnected: boolean
  getVideoPlayerState: (arg: boolean) => void
  lotGalleryControlStyle?: string
  galleryControl?: GalleryControlInterface
  setActiveLabel?: (arg: string) => void
}

const LotCarouselContent = ({
  gallery,
  session,
  isConnected,
  getVideoPlayerState,
  lotGalleryControlStyle = 'thumbnail',
  galleryControl,
  setActiveLabel,
}: LotCarouselContentInterface) => {
  const dotContainerRef = React.useRef<HTMLDivElement>(null)

  const [toggleControls, setToggleControls] = React.useState(true)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)

  const carouselContext = React.useContext(CarouselContext)
  const [slideCount, setSlide] = React.useState(
    carouselContext.state.currentSlide
  )

  const getImageSource = (lotGallery: LotGallery) => {
    if (lotGallery?.type === 'image' && lotGallery?.src) {
      return lotGallery?.src
    }
    return lotGallery?.thumbnail || ''
  }

  const updatePlayerState = (arg: React.SetStateAction<boolean>) => {
    setIsVideoPlaying(arg)
    getVideoPlayerState(arg === true)
  }

  const handleGallerySliderClick = () => {
    if (gallery.length > 1) {
      setToggleControls((state) => !state)
    }
  }

  React.useEffect(() => {
    const onChange = () => {
      setSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  React.useEffect(() => {
    setToggleControls(gallery.length > 1)
  }, [gallery])

  React.useEffect(() => {
    setActiveLabel?.(gallery?.[slideCount]?.label || '')
  }, [slideCount, gallery])

  return (
    <div
      onWheel={(e) => {
        if (!isVideoPlaying) {
          handleMouseWheel(
            e,
            'nextButton',
            'prevButton',
            slideCount,
            gallery.length
          )
        }
      }}
      className="h-full w-full"
    >
      {!isConnected && !isVideoPlaying && !galleryControl?.isPlaying && (
        <IdleTimeHandler>
          <FadeContainer className="z-2 w-full" show={toggleControls}>
            <div
              className={`fixed bottom-5 right-0 z-10 flex pr-7 ${
                !toggleControls && 'pointer-events-none'
              }`}
            >
              <div>
                <ButtonNext id="nextButton" className="hidden">
                  Next button
                </ButtonNext>
                <ButtonBack id="prevButton" className="hidden">
                  Prev button
                </ButtonBack>
                {gallery.length > 1 && (
                  <SlideTrigger
                    type={lotGalleryControlStyle}
                    items={gallery.map((item) => ({
                      text: item?.label || '',
                      src: getImageSource(item),
                      type: 'legacy',
                      noSpliceUrl: item?.noSpliceUrl,
                      gradiant: 0,
                    }))}
                    setCurrentSlide={setSlide}
                    currentSlide={slideCount}
                    nextButtonId="nextButton"
                    previousButtonId="prevButton"
                    dotContainerRef={dotContainerRef}
                    toggleControls={toggleControls}
                  />
                )}
              </div>
            </div>
          </FadeContainer>
        </IdleTimeHandler>
      )}
      <GallerySlider onClick={handleGallerySliderClick}>
        {gallery.map((item: LotGallery, itemIndex) => (
          <GallerySlide
            key={`${item?.src}-${itemIndex as number}`}
            className="h-full overflow-hidden"
            index={itemIndex}
            style={{ paddingBottom: 0 }}
          >
            <div className="relative">
              <ImageHandler
                className="background-cover image-blur absolute inset-0 z-2"
                url={getImageSource(item)}
                type="legacy"
                noSpliceUrl={item?.noSpliceUrl}
              />

              <ImageHandler
                className="background-contain absolute inset-0 z-3"
                url={getImageSource(item)}
                type="legacy"
                noSpliceUrl={item?.noSpliceUrl}
                bgProps={{
                  gradiant: item?.gradiant || 0,
                }}
              >
                {item?.type === 'video' && (
                  <Player
                    galleryName="lotGallery"
                    getPlayerState={updatePlayerState}
                    videoUrl={AssetHandler({
                      url: item?.src,
                      type: 'legacy',
                    })}
                    isActive={itemIndex === slideCount}
                    playerControls={session?.lotGallery?.playerControl}
                    disablePlayOption={galleryControl?.isPlaying}
                    onPlayerPauseOrStop={() => setToggleControls(true)}
                    timeoutInSeconds={3}
                  />
                )}
              </ImageHandler>
            </div>
          </GallerySlide>
        ))}
      </GallerySlider>
    </div>
  )
}

export default LotCarouselContent
