import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { selectHouseAndLand, setByFlag } from '@store/actionSlices/houseAndLand'
import { ShortlistState, updateShortlist } from '@store/actionSlices/shortlist'
import { PackageConfigurationInterface, RootStateFirebase } from '@store/types'

import {
  HouseAndLandActiveKeyType,
  LotInterface,
  LotStatusType,
  PackageInterface,
  PackageOptionsInterface,
} from '@api/types/house-and-land-type'

import LotConfiguration from './lot-configuration'
import LotPackage, { LotPackageProps } from './lot-package'
import ShortlistAction from './shortlist-action'

export interface LotCardProps {
  lot: LotInterface
  stageInfo: { id: string; label: string }
  activePrecinctId: string
  packages: Array<PackageInterface>
  activeStageId: string
  active: boolean
  shortlists: ShortlistState['shortlists']
  disableSoldLot: boolean
  packageOptions: Array<PackageOptionsInterface>
  onConfigurationValueChange?: (
    values: PackageConfigurationInterface,
    type?: keyof PackageConfigurationInterface,
    value?: string
  ) => void
  onConfigurationOptionSelect?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
  }) => void
  onConfigurationPropertyExpand?: (data: {
    propertyType: keyof PackageConfigurationInterface
    value: string
    expanded: boolean
  }) => void
}

const INITIAL_PACKAGE_CONFIGURATION: PackageConfigurationInterface = {
  floorplan: '',
  floorplanOption: '',
  facade: '',
  facadeColor: '',
  internalTheme: '',
  internalColorScheme: '',
}

const LotCard = ({
  lot,
  packageOptions,
  activeStageId,
  stageInfo,
  activePrecinctId,
  packages,
  active = false,
  shortlists,
  disableSoldLot,
  onConfigurationValueChange,
  onConfigurationOptionSelect,
  onConfigurationPropertyExpand,
}: LotCardProps) => {
  const dispatch = useDispatch()
  const { activePackageId } = useSelector(selectHouseAndLand)
  const defaultFloorplanId = React.useMemo(
    () => (packages.length > 0 ? packages[0].id : ''),
    [packages]
  )
  const validActivePackageId = React.useMemo(
    () => packages.find((item) => item.id === activePackageId)?.id,
    [activePackageId, packages]
  )
  const [packageConfiguration, setPackageConfiguration] =
    React.useState<PackageConfigurationInterface>({
      ...INITIAL_PACKAGE_CONFIGURATION,
      floorplan: active ? validActivePackageId || defaultFloorplanId : '',
    })
  const activePackage = React.useMemo(
    () =>
      active
        ? packages.find((pkg) => pkg.id === packageConfiguration.floorplan)
        : undefined,
    [packageConfiguration?.floorplan, active, packages]
  )

  const disable = React.useMemo(() => {
    if (disableSoldLot && lot.status !== LotStatusType.Available) {
      return true
    }
    return false
  }, [lot.status, disableSoldLot])

  const [transitionState, setTransitionState] = React.useState<
    'enter' | 'exited'
  >('exited')

  const isShortlisted = React.useMemo(
    () => shortlists.some((item) => item.propertyId === lot.id),
    [shortlists, lot.id]
  )

  const handleClick = React.useCallback(() => {
    if (activeStageId !== stageInfo.label) {
      dispatch(
        setByFlag({
          flag: HouseAndLandActiveKeyType.ActiveStageId,
          value: stageInfo.label,
        })
      )
    }
    dispatch(
      setByFlag({ flag: HouseAndLandActiveKeyType.ActiveLotId, value: lot.id })
    )
  }, [lot.id, stageInfo.label, activeStageId])

  const handleTransitionState = React.useCallback(
    (state: typeof transitionState) => () => {
      setTransitionState(state)
    },
    []
  )

  const handleConfigurationPropertyExpand = React.useCallback<
    NonNullable<LotPackageProps['onExpandProperty']>
  >(
    ({ propertyType, expanded }) => {
      onConfigurationPropertyExpand?.({
        propertyType,
        expanded,
        value: packageConfiguration[propertyType] || '',
      })
    },
    [onConfigurationPropertyExpand, packageConfiguration]
  )

  const handleChangePackageConfiguration = React.useCallback(
    (propertyType: keyof PackageConfigurationInterface, value: string) => {
      onConfigurationOptionSelect?.({ propertyType, value })
      if (propertyType === 'floorplan') {
        setPackageConfiguration({
          ...INITIAL_PACKAGE_CONFIGURATION,
          [propertyType]: value,
        })
        dispatch(
          setByFlag({
            flag: HouseAndLandActiveKeyType.ActivePackageId,
            value,
          })
        )
        return
      }
      if (propertyType === 'facade') {
        setPackageConfiguration((prevVal) => ({
          ...prevVal,
          [propertyType]: value,
          facadeColor: '',
        }))
        return
      }
      if (propertyType === 'internalTheme') {
        setPackageConfiguration((prevVal) => ({
          ...prevVal,
          [propertyType]: value,
          internalColorScheme: '',
        }))
        return
      }
      setPackageConfiguration((prevVal) => ({
        ...prevVal,
        [propertyType]: value,
      }))
    },
    [onConfigurationOptionSelect]
  )

  React.useEffect(() => {
    if (active) {
      setPackageConfiguration((prevVal) => ({
        ...prevVal,
        floorplan: validActivePackageId || '',
      }))
      return
    }
    setPackageConfiguration({ ...INITIAL_PACKAGE_CONFIGURATION })
  }, [active, validActivePackageId])

  React.useEffect(() => {
    onConfigurationValueChange?.(packageConfiguration)
  }, [onConfigurationValueChange, packageConfiguration])

  React.useEffect(() => {
    if (!(active && isShortlisted)) {
      return
    }

    dispatch(
      updateShortlist({
        propertyId: lot.id,
        data: {
          configuration: packageConfiguration,
        },
      })
    )
  }, [active, isShortlisted, packageConfiguration, lot.id])

  return (
    <div
      role="none"
      id={lot.id}
      className={`overflow-hidden rounded-md transition-[border] ${
        active ? 'border-l-[7px] border-mainColour' : 'cursor-pointer'
      } bg-white ${disable ? 'pointer-events-none !bg-[#c2c2c2]' : ''}`}
      onClick={active ? undefined : handleClick}
    >
      <div className="px-6 py-4">
        <div className="mb-2 flex justify-between gap-1">
          <div className="flex min-w-0 items-center">
            <div className="flex-1 truncate">
              <span className="text-[18px] font-bold" title={lot.name}>
                Lot {lot.name}
              </span>
            </div>
            <span
              className={`ml-4 h-2 w-2 rounded-full ${
                lot.status === LotStatusType.Available
                  ? 'bg-green-500'
                  : 'bg-red-500'
              }`}
            ></span>
            <span
              className={`ml-1 text-xs font-semibold uppercase tracking-widest ${
                lot.status === LotStatusType.Available
                  ? 'text-green-500'
                  : 'text-red-500'
              }`}
            >
              {packages.length} {packages.length > 1 ? 'Packages' : 'Package'}
            </span>
          </div>
          <ShortlistAction
            actionType="icon"
            lot={lot}
            stageId={stageInfo.label}
            precinctId={activePrecinctId}
            isShortlisted={isShortlisted}
            totalShortlist={shortlists.length}
            packageConfiguration={packageConfiguration}
          />
        </div>
        <LotConfiguration
          {...(activePackage?.configuration || {})}
          area={lot.area}
          frontage={lot.frontage}
        />
        <CSSTransition
          in={active}
          classNames="transition-lot-package-expand"
          timeout={500}
          onEnter={handleTransitionState('enter')}
          onExited={handleTransitionState('exited')}
        >
          {!active && transitionState === 'exited' ? (
            <></>
          ) : (
            <div className="overflow-hidden">
              <div className="mt-4 border-t-2 pb-2 pt-4">
                <LotPackage
                  packages={packages}
                  packageOptions={packageOptions}
                  values={packageConfiguration}
                  onChange={handleChangePackageConfiguration}
                  onExpandProperty={handleConfigurationPropertyExpand}
                />
              </div>
              <ShortlistAction
                actionType="button"
                lot={lot}
                stageId={stageInfo.label}
                precinctId={activePrecinctId}
                isShortlisted={isShortlisted}
                totalShortlist={shortlists.length}
                packageConfiguration={packageConfiguration}
              />
            </div>
          )}
        </CSSTransition>
      </div>
    </div>
  )
}
export default connect(
  ({
    shortlist: { shortlists },
    projectConfig: { disableSoldUnit },
    houseAndLand: { packages, activeStageId, activePrecinctId },
  }: RootStateFirebase) => ({
    shortlists,
    disableSoldLot: disableSoldUnit, // TODO: change it to disableSoldLot when BE has it done
    packageOptions: packages,
    activeStageId,
    activePrecinctId,
  })
)(LotCard)
