import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import {
  MatrixDataInterface,
  PackageOptionsInterface,
  PrecinctListItemInterface,
} from './types/house-and-land-type'

type PackageByIdRequestParameters = {
  projectName: string
  packageIds?: string
  lotId?: string
}

type MatrixDataRequestParameters = {
  projectName: string
  precinctIdOrLabel: string
  lots?: boolean
}

type PrecinctListRequestParameters = {
  projectName: string
}

const API_VERSION = 'v3'

export const houseAndLandApi = createApi({
  reducerPath: 'houseAndLandApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getMatrixDataByPrecinct: builder.query<
      ApiResponse<MatrixDataInterface>,
      MatrixDataRequestParameters
    >({
      query: (params: MatrixDataRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages/summary`,
        method: 'get',
        params: {
          precinctIdOrLabel: params.precinctIdOrLabel,
          lots: params.lots || false,
        },
      }),
    }),

    getPackagesById: builder.query<
      ApiResponse<Array<PackageOptionsInterface>>,
      PackageByIdRequestParameters
    >({
      query: (params: PackageByIdRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages`,
        method: 'get',
        params: {
          packageIds: params.packageIds,
          lotId: params.lotId,
        },
      }),
    }),

    getPrecinctList: builder.query<
      ApiResponse<Array<PrecinctListItemInterface>>,
      PrecinctListRequestParameters
    >({
      query: (params: PrecinctListRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/precincts/list`,
        method: 'get',
      }),
    }),
  }),
})

export const selectMatrixDataFromResult = ({
  data,
  isLoading,
  isError,
  isFetching,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  matrixData: MatrixDataInterface
  isLoaded: boolean
  isError: boolean
  isFetching: boolean
  errorStatus: number
  status: string
} => ({
  matrixData: data?.data || {},
  isLoaded: !isLoading,
  isError,
  isFetching,
  status,
  errorStatus: error?.status,
})

export const selectPackagesFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  packages: Array<PackageOptionsInterface>
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  packages: data?.data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const selectPrecinctListFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  precinctList: Array<PrecinctListItemInterface>
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  precinctList: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const {
  useGetMatrixDataByPrecinctQuery,
  useLazyGetMatrixDataByPrecinctQuery,
  useGetPackagesByIdQuery,
  useGetPrecinctListQuery,
  useLazyGetPackagesByIdQuery,
} = houseAndLandApi
