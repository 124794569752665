import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { ChevronSvg } from '@components/adgroup-svg/react'

import PropertyOption, { Option } from './property-option'

interface SelectPropertyProps {
  label: string
  options: Option[]
  value: string
  onChange: (value: string) => void
  canDeselct?: boolean
  placeholder?: string
  expandOptions: boolean
  onClick: () => void
  showAsThumbnail?: boolean
}

const SelectProperty = ({
  label,
  options,
  value,
  onChange,
  canDeselct,
  placeholder = 'Select',
  expandOptions,
  onClick,
  showAsThumbnail,
}: SelectPropertyProps) => {
  const selectedOption = React.useMemo(
    () => options.find((option) => option.id === value),
    [value, options]
  )
  const showOptions = React.useMemo(
    () => !!options.length && expandOptions,
    [expandOptions, options]
  )
  const [transitionState, setTransitionState] = React.useState<
    'enter' | 'exited'
  >('exited')

  const handleClick = React.useCallback(() => {
    if (!options.length) {
      return
    }
    onClick()
  }, [options, onClick])

  const handleChange = React.useCallback(
    (updatedValue: string) => () => {
      if (updatedValue === value) {
        if (canDeselct) {
          onChange('')
        }
        return
      }
      onChange(updatedValue)
    },
    [onChange, value, canDeselct]
  )

  const handleTransitionState = React.useCallback(
    (state: typeof transitionState) => () => {
      setTransitionState(state)
    },
    []
  )

  return (
    <div className="flex flex-col overflow-hidden bg-white">
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={handleClick}
        role="none"
      >
        <div className="flex flex-col">
          <p className="counter-list-item text-[13px] font-semibold text-[#666666]">
            {label}
          </p>
          <SwitchTransition>
            <CSSTransition
              key={`${selectedOption?.id}_${selectedOption?.name}`}
              classNames="transition-lot-select-property-text-change"
              timeout={300}
            >
              <p
                className={`text-[18px] font-medium ${
                  selectedOption?.name ? 'text-black' : 'text-[#666666]'
                }`}
              >
                {selectedOption?.name || placeholder}
              </p>
            </CSSTransition>
          </SwitchTransition>
        </div>
        {options.length ? (
          <ChevronSvg
            className="h-6 w-6 transition-all duration-500"
            rotate={showOptions ? 'top' : 'down'}
          />
        ) : null}
      </div>
      <CSSTransition
        in={showOptions}
        classNames="transition-lot-select-property-expand"
        timeout={500}
        onEnter={handleTransitionState('enter')}
        onExited={handleTransitionState('exited')}
      >
        <div className="mt-4 flex grid grid-cols-2 flex-col gap-3">
          {(!showOptions && transitionState === 'exited' ? [] : options).map(
            (option) => (
              <PropertyOption
                key={option.id}
                option={option}
                isSelected={value === option.id}
                onClick={handleChange(option.id)}
                showAsThumbnail={!!showAsThumbnail}
              />
            )
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

export default SelectProperty
